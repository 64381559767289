<template>
  <Layout>
    <div class="row page-title align-items-center">
      <div class="col-md-3 col-xl-6">
        <h4 class="mb-1 mt-0">MUAYTHAI TRAINING IDEA</h4>
        <h6 class="mb-1 mt-0">Fighter List</h6>
      </div>
      <div class="col-md-3 col-xl-6">
        <div>
          <!-- <router-link :to="`/trainingIdea/content/create?categoryID=${this.id}`"> -->
          <router-link :to="`/trainingIdea/manage/fighter`">
            <button
              class="
                btn btn-primary
                rounded-pill
                width-lg
                float-right
                aligncenter
              "
            >
              <feather type="file-plus"></feather>Create Fighter
            </button>
          </router-link></div
        >
        <!-- <button
          @click=";(modalShow = !modalShow), getSubTitle()"
          class="btn btn-primary rounded-pill width-lg float-right aligncenter mr-2"
        >
          <feather type="edit"></feather>Sub-Title
        </button> -->
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <Search :types="search_types" @onSearch="onSearch($event)">
              <!-- <div class="input-group" style="margin-right:5px">
                <div class="input-group-prepend">
                  <label class="input-group-text">Sort by:</label>
                </div>
                <select
                  class="custom-select"
                  v-model="sort_by"
                  @change="onLoad()"
                >
                  <option value>All</option>
                  <option
                    v-for="x in sortByList"
                    :key="x.key"
                    :value="x.value"
                    >{{ x.key }}</option
                  >
                </select>
              </div> -->

              <!-- <div class="input-group" style="margin-right:5px">
                <div class="input-group-prepend">
                  <label class="input-group-text">Type:</label>
                </div>
                <select
                  class="custom-select"
                  v-model="sort_type"
                  @change="onLoad()"
                >
                  <option value>All</option>
                  <option
                    v-for="x in sortByType"
                    :key="x.key"
                    :value="x.value"
                    >{{ x.key }}</option
                  >
                </select>
              </div> -->
            </Search>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table mb-0">
                <thead class="thead-light">
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Content</th>

                    <th scope="col" class="tf-2">Title</th>
                    <th scope="col" class="tf-3">Update At</th>
                    <!-- <th scope="col">Anthor</th> -->
                    <th scope="col">Status</th>
                    <th scope="col">HighLight</th>

                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(content, index) in data" :key="index">
                    <td scope="row">
                      <!-- {{ ++index }} -->
                      {{
                        page == 1
                          ? page - 1 + index + 1
                          : (page - 1) * 10 + (index + 1)
                      }}
                    </td>

                    <td scope="row" style="max-width: 175px">
                      <iframe
                        v-if="content.video_data"
                        :src="
                          `https://player.vimeo.com/video/${content.video_data.vimeoid}`
                        "
                        width="225"
                        height="125"
                        frameborder="0"
                        allow="  fullscreen"
                        allowfullscreen
                      ></iframe>
                      <img
                        v-else
                        style="width: 225px; height: 163px"
                        :src="`${baseImageUrl}/${content.img_url}`"
                        alt
                        class="img-fluid"
                      />
                    </td>

                    <td scope="row" style="width: 200px; max-width: 250px">
                      {{ content.content_title }}
                    </td>

                    <td scope="row" style="max-width: 150px">{{
                      content.updatedAt.substring(0, 10)
                    }}</td>

                    <!-- <td scope="row" v-if="content.author">
                      {{
                        content.author.firstName + ' ' + content.author.lastName
                      }}
                    </td> -->

                    <td scope="row" style="max-width: 150px">
                      <b-badge v-if="content.status" variant="primary"
                        >Show</b-badge
                      >
                      <b-badge v-else variant="secondary">Hide</b-badge>
                    </td>

                    <!-- <td scope="row" style="max-width: 150px;">
                      <span v-if="content.is_paid">Paid</span>
                      <span v-else>Free</span>
                    </td> -->
                    <td scope="row" style="max-width: 150px">
                      <b-badge v-if="content.feat" variant="success"
                        >True</b-badge
                      >
                      <b-badge v-else variant="secondary">False</b-badge>
                    </td>
                    <td>
                      <div style="width:fit-content">
                        <div>
                          <button
                            class="btn btn-info p-0 px-2 py-1 mb-2 w-100"
                            @click="handleClickAddBodyParts(content)"
                            >Body Parts
                          </button>
                        </div>                      
                        <div class="d-flex">
                          <button
                            class="btn btn-warning p-0 px-2 py-1 mr-2"
                            @click="handleClickEditContent(content)"
                            >Edit
                          </button>
                          <div>
                            <b-button
                              @click="handleClickDeleteContent(content._id)"
                              class="btn btn-danger p-0 px-2 py-1"
                              v-b-modal.modal-center
                              >Delete
                            </b-button>
                            </div>
                          </div>
                        </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <Pagination
              :data="pagination"
              :page="page"
              @onPage="onPage($event)"
            />
          </div>
        </div>
      </div>
    </div>
    
    <!-- <b-modal size="lg" title="sub-title" v-model="modalShow" @ok="handleOk"
      ><b-form-textarea
        id="textarea"
        v-model="sub_title"
        rows="3"
        max-rows="6"
      ></b-form-textarea
    ></b-modal> -->
  </Layout>
</template>

<script>
import appConfig from '@src/app.config'
import Layout from '@layouts/main'
// import PageHeader from '@components/page-header'
import axios from '@/axios'
import Pagination from '@src/components/base/pagination'
import Search from '@src/components/base/search'
import { BASE_IMAGE_URL } from '@src/config/configs'
import moment from 'moment'
/**
 * Contents component
 */
export default {
  page: {
    title: '',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  components: { Layout, Pagination, Search },
  data() {
    return {
      sub_title: '',
      modalShow: false,
      baseImageUrl: BASE_IMAGE_URL,
      title: '',
      catName: '',
      search_types: [
        { key: 'content_title', value: 'Title' },
        { key: 'content_text', value: 'Text' },
      ],
      sortByList: [
        { key: 'Video', value: true },
        { key: 'Content', value: false },
      ],
      sortByType: [
        { key: 'Free', value: false },
        { key: 'Paid', value: true },
      ],
      search: {},
      page: 1,
      data: [],
      pagination: {},
      sort_by: '',
      sort_type: '',
      id: '',
    }
  },
  mounted() {
    this.id = this.$route.query.id
    this.onLoad()
    // console.log(this.catName);
    // this.getSubTitle()
    // console.log(this.id);
  },

  methods: {
    async handleOk(bvModalEvt) {
      await axios.patch(`/api/config?name=contentlabel`, {
        name: 'contentlabel',
        text: this.sub_title,
      })
    },
    async getSubTitle() {
      await axios.get(`/api/config?name=contentlabel`).then((result) => {
        this.sub_title = result.data.text
      })
    },
    onPage(page) {
      this.page = page
      this.onLoad()
    },
    onSearch(search) {
      this.search = search
      this.page = 1
      this.onLoad()
    },
    async onLoad() {
      await axios
        .get(`/api/trainingidea/getAllFighter`, {
          params: {
            page: this.page,
            // ...this.search,
            // is_video: this.sort_by,
            // is_paid: this.sort_type,
            // orderByField: 'createdAt',
            // orderBy: 'desc',
            // type: 'free',
            size: 100,
            // category: this.id,
          },
        })
        .then((res) => {
          this.data = res.data.data
          this.pagination = res.data
        })
        .catch((err) => {
          alert(err.response.data.message)
        })
    },
    async handleClickDeleteContent(id) {
      console.log(id, 'id jaaaa')
      var confirmDel = confirm('Are you sure you want to delete?')

      if (confirmDel) {
        await axios
          .delete(`/api/trainingIdea/deleteFighter/${id}`)
          .then((result) => {
            this.onLoad()
          })
          .catch((err) => {
            alert(err.response.data.error.message)
          })
      }
    },
    async handleClickswitchContent(id) {
      console.log(id, 'id swicth')
    },
    async handleClickEditContent(content) {
      // console.log(123);
      this.$router.push({
        // path: '/newPremium/content/createVideo',
        path: '/trainingIdea/manage/fighter',
        query: { id: content._id },
      })
    },
    async searchByHashtag(x) {
      this.search = {
        hashtag: x._id,
      }
      this.onLoad()
    },
    async handleClickAddBodyParts(content) {
      // console.log(content);
      this.$router.push({
        path: '/trainingIdea/bodyParts',
        // path: '/newPremium/content',
        query: { id: content._id },
      })
    },
  },
}
</script>
